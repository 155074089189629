.dropdown {
  position: relative;
  display: inline-block;
  margin: 10px;
}

.dropdown_container {
  display: flex;
}

.dropdown_toggle {
  background: none;
  border: none;
  cursor: pointer;
  font: inherit;
  padding: 5px;
}

.dropdown_menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 150px;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--border-color);
  border-radius: 7px;
  z-index: 99;
}

.dropdown_menu a {
  display: block;
  padding: 9px 10px;
  border-radius: 7px;
  transition: background-color 0.1s ease-in-out;
}

.dropdown_menu a:hover {
  background-color: #f3f3f3;
}

.dropdown_link img {
  width: 17px;
  margin-bottom: -3px;
}

.dropdown_link {
  margin-bottom: 0px;
}