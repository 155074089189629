.apps-apps {
  background-color: #1a2023;
  color: white;
  min-height: 87vh;
}

.apps-apps-container {
  text-align: center;
  padding: 20px;
  width: 80%;
  margin: 10px auto;
  @media (max-width: 1000px) {
    width: 90%;
  }
}

.apps-apps-container h1 {
  color: white;
  margin: 5px;
  text-align: center;
}

.apps-apps-container input {
  border: none;
  padding: 5px;
  border-radius: 5px;
  margin: 2.5vh 0px 5vh 0px;
  text-align: center;
  font-size: 1.5em;
  width: calc(120px + 20vw);
  height: 40px;
  background-color: rgb(15, 15, 15);
  color: white;
  outline: none;
}

.apps-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin-bottom: 25px;
  @media (max-width: 1000px) {
    gap: 15px;
  }
}

.apps-appbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
  width: 10%;
  transition:  0.3s ease;
  border-radius: 3vh;
  transition: all 0.4s;
  padding: 0.5rem 1rem 0px;
  min-width: 125px;
  cursor: pointer;
}

.apps-appbox:hover {
  transform: scale(1.2);
  box-shadow: 0px 2px 23px 7px rgba(255, 0, 0, 0.8);
}

.apps-appbox img {
  aspect-ratio: 1 / 1;
  margin-top: 5px;
  border-radius: 5px;
  width: 125px;
  @media (max-width: 1000px) {
    width: 100px;
  }
}

