.home {
  background-color: rgb(251, 251, 251) !important;
  min-height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  min-width: 370px;
}

.home_container {
   width: 150vh;
  z-index: 1;
  @media (max-width: 1030px) {
    width: 95%;
  }
}

.no-results {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.no-results button {
  outline: none;
  border: none;
  cursor: pointer;
  padding: calc(0.17vw + 5px) 20px;
  line-height: calc(20px + 0.3vw);
  margin: 10px 0px;
  box-shadow: 0px 5px 20px 3px rgba(188, 188, 188, 0.2);
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(0, 145, 255);
  color: rgb(4, 125, 218);
  font-size: calc(0.35vw + 15px);
  transition: background-color 0.2s ease-in-out;
}

.no-results button:hover {
  background-color: rgba(0, 145, 255, 0.1);
}

.loader {
  margin: auto;
  margin-top: 15px;
  font-size: calc(0.2vw + 15px);

}

.tools {
  display: flex;
  align-items: center;
  margin: calc(0.3vw + 8px) 0px;
}

.tools .tool_boxes {
  overflow-x: scroll;
  display: flex;
  overflow: hidden;
}

.tool_boxes span {
  padding: calc(0.3vw + 5px) calc(0.8vw + 6px);
  margin: 0px 0.25vw;
  min-width: fit-content;
  border: 1px solid var(--border-color);
  background-color: white;
  border-radius: 9px;
  font-size: calc(0.2vw + 12px);
  font-weight: 600;
  cursor: pointer;
}

.tool_boxes span.selected {
   background-color: black;
   color: white;
 }

.tools .arrow {
  background-color: white;
  color: rgb(151, 151, 151);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  height: calc(1vw + 17px);
  width: calc(0.75vw + 17px);
  padding: 4px;
  cursor: pointer;
}

.left {
  margin-right: 5px;
}

.right {
  margin-left: 5px;
}

.all_websites {
   display: flex;
   flex-wrap: wrap;
   justify-content: left; /* Distributes space between boxes */
   margin: 0 auto;
   width: auto;
   width: 100%;
}

.all_websites:last-child {
  margin-bottom: 15px;
}

.tool_boxes span.selected {
  background-color: black;
  color: white;
}

.left_div,
.right_div {
  flex: 1; /* This makes the divs take up remaining space */
  padding: 20px;
}

.ads-component {
   padding-bottom: 150px;
}

/* Media query for smaller screens */
@media screen and (max-width: 975px) {
   .all_websites {
      display: flex;
      flex-wrap: wrap;
      justify-content: left; /* Distributes space between boxes */
      margin: 0 auto;
      width: auto;
      padding-left: 0px;
      padding-right: 0.5px;
   }

   .tools {
      margin-top: 10px;
      margin-bottom: 10px;
   }

   .tool_boxes span {
      margin-left: 4px;
      margin-right: 4px;
   }
}
