.navigation {
  width: 100%;
  background-color: rgb(251, 251, 251);
  position: relative;
  height: fit-content;
  z-index: 999;
  /* border-bottom: 1px solid var(--border-color); */
}

.nav_container {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 56%;
  margin: 0px auto;
  padding: 5px 0px;
  min-width: 370px;
  @media (max-width: 1030px) {
    width: 90%;
  }
}

.logo {
  padding: 0px 0.9vw;
}

.logo img {
  width: 40px;
  border-radius: 10px;
}

.topics {
  display: flex;
  align-items: center;
  font-size: calc(12px + 0.25vw);
  font-weight: 600;
}

.topics > div, .topics > a {
  margin: 0px 15px;
}

a, .dropdown_toggle {
  text-decoration: none;
  cursor: pointer;
  color: rgb(39, 39, 39);
}

.search_container {
  display: flex;
  color: rgba(188, 188, 188, 0.8);
  margin-left: auto;
}

.search_container svg {
  align-self: center;
  cursor: pointer;
  margin-right: 0.5vw;
  font-size: calc(0.35vw + 15px);
}

.search_modal {
  position: absolute;
  z-index: 9999 !important;
}

.subscribe_btn {
  cursor: pointer;
  padding: calc(0.17vw + 3px) 12px;
  line-height: calc(20px + 0.3vw);
  margin-right: 13px;
  box-shadow: 2px 2px 2px rgba(188, 188, 188, 0.15);
  border-radius: 7px;
  background-color: rgb(0, 145, 255);
}

.subscribe_btn p {
  margin: 0;
  display: inline;
  font-size: calc(0.25vw + 12px);
  font-weight: 600;
  position: relative;
  color: white;
}

.smallnavbar_container {
  position: relative;
  margin-left: 10px;
  margin-right: 15px;
  cursor: pointer;
}

.smallnavbar_container svg {
  color: rgba(0, 0, 0, 0.5);
  font-size: 32px;
}

.menu_open {
  position: fixed;
  top: 0;
  right: calc(-250px - 15vw); /* Initially hidden */
  width: calc(250px + 8vw);
  height: 100%;
  background-color: #ffffff;
  border: 1px solid var(--border-color);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 0px 10px;
  color: rgb(0, 0, 0);
  z-index: 40;
  transition: right 0.3s ease-out;
}

.menu_open.active {
  right: 0; /* Slide in from the right */
}

.menu_open ul {
  list-style: none;
  padding: 0px;
  margin: 0;
}

.menu_open ul li {
  padding: 15px 25px;
}

.top_container {
  display: flex;
}

.popup-background {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   backdrop-filter: blur(2px); /* apply blur effect to the background */
   z-index: 999; /* ensure the popup is on top of other elements */
}

.popup {
   width: 700px;
   background: #ffffff;
   border-radius: 6px;
   border-style: solid;
   border-width: 1px;
   border-color: #000;
   position: fixed; /* Changed from absolute to fixed */
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%) scale(0.1);
   text-align: center;
   padding: 30px;
   visibility: hidden;
   transition: transform 0.4s, top 0.4s;
   z-index: 9999;

   @media (max-width: 1030px) {
      width: 80%;
   }
}

.open-popup {
   visibility: visible;
   top: 50%;
   transform: translate(-50%, -50%) scale(1);
}

.popup .xbutton {
   line-height: 12px;
   width: 18px;
   font-size: 20pt;
   cursor: pointer;
   margin-top: 11px;
   margin-right: 11px;
   position:absolute;
   top:0;
   right:0;
   color: black;
}

 @keyframes animatedgradient {
   0% {
       background-position: 0% 50%;
   }
   50% {
       background-position: 100% 50%;
   }
   100% {
       background-position: 0% 50%;
   }
 }

 @keyframes borderAnimation {
   0%, 100% {
     transform: scale(100%);
   }
   50% {
     background: #a1a1ff;
     transform: scale(105%);
   }
 }


@-webkit-keyframes Gradient {
  0% {
  background-position: 0% 50%
  }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
}

@-moz-keyframes Gradient {
  0% {
  background-position: 0% 50%
  }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
}

@keyframes Gradient {
  0% {
  background-position: 0% 50%
  }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
}