.searchbar-area {
   display: flex;
   align-items: flex-start; /* Align items at the top */
   justify-content: center; /* Center the search bar horizontally */
   margin: calc(0.4vw + 4px) 0px;
   position: relative;
   margin-bottom: 10px;
 }

.filter {
  width: 2.2vw; /* Half of the viewport width */
  height: 2vw;
  min-height: 25px;
  padding: 16px;
  background-color: white;
  border-radius: 16px;
  border: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;

  @media (max-width: 800px) {
    width: 7vw;
  }
}

.filter:hover {
  background-color: #f0f0f0;
}

.tune-icon {
  width: 27px !important; /* Increased from 25px */
  height: 27px !important; /* Adjust the height as well if necessary */
}

.searchbar {
  width: 40vw; /* Half of the viewport width */
  height: 2vw;
  min-height: 25px;
  padding: 16px;
  background-color: white;
  border-radius: 16px;
  border: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.searchbar-input-box {
  width: 100%;
  padding: 8px;
  border: none;
  outline: none;
  font-size: 19px;
  border-radius: 4px;
}

.search-icon {
   margin-top: 1.5px;
   margin-left: 3px;
   margin-right: 4px;
   width: 30px !important; /* Increased from 25px */
   height: 35px !important; /* Adjust the height as well if necessary */
   color: #c8c8c8;
 }

.select {
  display: flex;
  align-items: center;
}

.active {
  background: #23242a;
}

/* Existing .pill styles */
.pill {
  padding: calc(0.4vw + 6px) calc(0.9vw + 8px); /* Slightly reduced padding */
  margin: 0px 0.25vw;
  min-width: fit-content;
  border: 1px solid var(--border-color);
  background-color: white;
  border-radius: 16px; /* Slightly reduced border-radius */
  font-size: calc(0.25vw + 12px); /* Slightly reduced font size */
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.pill:hover {
  background-color: #ebebeb; /* adjust the hover color */
}

/* Styles for when a pill is selected */
.pill.selected {
  background-color: rgb(0, 145, 255);
  color: white;
}

/* Add this to style the pills container */
.pills-container {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Adjust as necessary to create space below the search bar */
  margin-bottom: 20px;
}

.all-filters {
  position: relative;
}

.filter-menu {
  position: absolute;
  background-color: white;
  border: 1px solid var(--border-color);
  border-radius: 12px;
  z-index: 99;
  top: -25%;
  right: 100%;
  transform: translateX(40px);
  transition: opacity 0.5s, transform 0.5s; /* Smooth transition for opacity and transform */
  visibility: hidden;
}

.filter-menu.open {
  opacity: 1;
  transform: translateX(0);
  visibility: visible; /* Show the menu */
  box-shadow: 0px 5px 21px 1px rgba(0,0,0, 0.2);
}

.filter-container {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 10px;
  min-width: 500px;

  @media (max-width: 800px) {
    min-width: 72vw;
    grid-template-rows: repeat(6, 1fr);
    grid-template-columns: repeat(2, 1fr);
  }
}

.filter-tool {
  padding: 10px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
}

.filter-tool.selected {
  background-color: black;
  color: white;
}

.filter-tool.selected:hover {
  background-color: black;
}

.filter-tool:hover {
  background-color: #f0f0f0;
}

.filter-tool h3 {
  margin: 0px;
  font-size: calc(0.3vw + 12px);
}

@media screen and (max-width: 800px) {

  .pill {
    padding: calc(0.6vw + 10px) calc(1.2vw + 12px); /* Increased padding */
    margin: 0px 0.25vw;
    min-width: fit-content;
    border: 1px solid var(--border-color);
    background-color: white;
    color: black;
    font-size: calc(0.4vw + 14px); /* Increased font size */
    font-weight: 600;
    cursor: pointer;
    border-radius: 14px;
    margin: 0px 0.50vw;
  }

  .searchbar {
    width: 80vw; /* Half of the viewport width */
  }

}
