.not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 88vh;
}

.not-found p {
  color: white;
  font-size: 5vw;
  cursor: default;
}