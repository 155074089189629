:root {
  --width: 69%;
  --border-color: #eeeeee;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  padding: 0px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  height: 40px;
  width: 100%;
  font-size: 13px;
  border-top: 1px solid rgb(233, 233, 233);
}