.unsubscribe {
  height: 84vh;
  min-height: 300px;
  background-color: rgb(251, 251, 251) !important;
  padding: 20px;
}

.unsubscribe-container {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  background-color: #f6f9fc;
  color: #525f7f;
  max-width: 500px;
  margin: 50px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(50,50,93,0.11), 0 1px 3px rgba(0,0,0,0.08);
}

.unsubscribe h1 {
  color: #32325d;
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.unsubscribe-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-label {
  font-size: 16px;
  color: #32325d;
}

.form-input {
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #e6ebf1;
  transition: border-color 0.15s ease-in-out;
}

.form-input:focus {
  outline: none;
  border-color: #6772e5;
}

.unsubscribe-button {
  background-color: rgb(0, 145, 255);
  color: #fff;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;
}

.unsubscribe-button:hover {
  background-color: rgb(0, 130, 230);
}

.message {
  margin-top: 15px;
  text-align: center;
  color: #274d70;
  font-size: 15px;
  white-space: pre-line;
}
