.website_background {
   background-color: rgb(255, 255, 255);
   border: 1px solid var(--border-color);
   border-radius: 13px;
   margin: 8px;
   width: calc(33% - 15px); /* Increase by 3 pixels */
   position: relative;
   height: auto;
   min-width: 200px;
   transition: box-shadow 0.1s ease-in-out;
 }

.website_background:hover {
  box-shadow: 0px 15px 15px rgba(50, 50, 50, 0.05);
}

.website_container {
  display: flex;
  flex-direction: column;
  height: 91%;
  padding: 17px;
  margin-top: 6px;
  margin-left: 6px;
  margin-right: 6px;
}

.website_container img {
   border-radius: 10px;
   cursor: pointer;
   aspect-ratio: 16 / 9;
   max-width: 100%;
   height: auto;
}

 .headerandspan {
   display: flex;
   align-items: center; /* Adjusts the vertical alignment */
   justify-content: space-between; /* Adjusts the horizontal spacing */
 }

.headerandspan h2 {
  margin: 5px 0px;
  margin-right: 10px !important;
}

.headerandspan .tag {
  margin: 0px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.website_container h2 {
   margin: 5px 0px;
 }

 .website_container .tag {
   margin: 0px;
   font-size: 12px;
   display: flex;
   flex-direction: row;
   align-items: center;
 }

.website_container .site_cost {
  display: block;
  margin: 0px;
  margin-left: auto;
  background-color: rgba(164, 199, 234, 0.3);
  padding: 3px 5px;
  border-radius: 5px;
}

.website_container .summary {
  margin: 3px 0px;
  color: rgb(108, 108, 108);
}

.website_container button {
  background-color: white;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  margin: 5px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.bookmark_button {
   width: 12% !important;
   min-width: 45px !important;
   margin-left: 10px !important;
}

.learnmore_button {
   font-size: 15px;
}

.website_container button:hover {
  background-color: rgba(242, 242, 242, 0.5);
}

.website_container .MuiSvgIcon-learnmore {
  width: 17px;
  margin-left: 4px;
}

.website_container .MuiSvgIcon-bookmarks {
   width: 22px;
 }

.bookmark_learnmore {
  margin-top: auto;
  padding-top: 10px;
  margin-bottom: 4px;
  display: flex;
}

.website_name {
  cursor: pointer;
}

.summary {
  padding-top: 5px;
}

.popup-background {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   backdrop-filter: blur(2px); /* apply blur effect to the background */
   z-index: 999; /* ensure the popup is on top of other elements */
}

.popup {
   width: 550px;
   background: #ffffff;
   border-radius: 6px;
   border-style: solid;
   border-width: 1px;
   border-color: #000;
   position: fixed; /* Changed from absolute to fixed */
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%) scale(0.1);
   text-align: center;
   padding: 30px;
   visibility: hidden;
   transition: transform 0.4s, top 0.4s;
   z-index: 9999;

   @media (max-width: 1030px) {
      width: 80%;
   }
}

.open-popup {
   visibility: visible;
   top: 50%;
   transform: translate(-50%, -50%) scale(1);
}

.popup button {
   width: 100%;
   margin-top: 27px;
   padding: 10px 0;
   background: #126EF7;
   color: #fff;
   border: 0;
   cursor: pointer;
   outline: none;
   font-size: 18px;
   border-radius: 4px;
   box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}

.popup button:disabled {
  opacity: 0.6;
}

.popup .xbutton {
   line-height: 12px;
   width: 18px;
   font-size: 20pt;
   cursor: pointer;
   margin-top: 11px;
   margin-right: 11px;
   position:absolute;
   top:0;
   right:0;
   color: black;
}

@media (max-width: 1330px) {
  .website_background {
    width: calc(48%);
  }
}

@media (max-width: 900px) {
  .website_background {
    width: calc(98%);
  }
  .bookmark_button {
   width: 15% !important;
   margin-left: 10px !important;
}
}

/* .featured-border {
  --borderWidth: 3px;
  position: relative;
  border-radius: 11px;
}

.featured-border:after {
  --featuredWidth: 4px;
  content: '';
  position: absolute;
  top: calc(-1 * var(--featuredWidth));
  left: calc(-1 * var(--featuredWidth));
  height: calc(100% + var(--featuredWidth) * 2);
  width: calc(100% + var(--featuredWidth) * 2);
  background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
  border-radius: 13px;
} */

.boujee-text {
  --bg-size: 400%;
  --color-one: #ffb50a;
  --color-two: #ffc20acc;
  --color-three: #ffbe0ac8;
  --color-four: #ff5c0ac1;
  --color-five: #ff0e0ab6  ;
  background: linear-gradient(
                90deg,
                var(--color-three),
                var(--color-four),
                var(--color-five),
                var(--color-four),
                var(--color-three)
              ) 0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: move-bg 8s infinite linear;
}

@media (prefers-reduced-motion: no-preference) {
  .boujee-text {
    animation: move-bg 8s linear infinite;
  }
  @keyframes move-bg {
    to {
      background-position: var(--bg-size) 0;
    }
  }
}