.rectangle {
   width: 100%;
   background-color: white;
   border: 1px solid var(--border-color);
   border-radius: 15px;
   position: relative;
   background: rgb(255,255,255);
   /* background: linear-gradient(160deg, rgba(255,255,255, 1) 35%, rgba(85,184,255,1) 100%); */
   z-index: 2;
}

.content-container {
   position: relative;
   padding: 25px 40px;
   width: calc(100% - 80px);
   display: flex;
   flex-direction: column;
   align-items: flex-start;
 }

.text-container {
   text-align: left;
   width: 100%;
   margin-bottom: 15px;
 }

.text-container h2 {
  margin-top: 25px;
  margin-bottom: 0px;
  font-size: 37.5px;
}

.text-container p {
   font-size: 20px;
   line-height: 1.5;
   margin-top: 15px;
   margin-bottom: 15px;
 }

.input-container {
   width: 80%;
   padding: 12px 0;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   background-color: white;
   border-radius: 13px;
   margin-bottom: 10px;
 }

.input-box {
   width: calc(70% - 30px);
   padding: 10px;
   border: none;
   outline: none;
   font-size: 1rem;
   border-radius: 4px;
   margin-left: 13px;
}

.subscribe-button {
   background-color: #000 !important;
   color: white;
   border: none;
   padding: 8px 12px !important;
   border-radius: 5px;
   cursor: pointer;
   font-size: 1rem;
   font-weight: 600;
   margin-right: 13px;
   margin-top: 0px !important;
   width: 22% !important;
   min-width: 100px;
}

.success-container {
   display: flex;
   position: fixed;
   flex-direction: column;
   align-items: center;
   background-color: rgb(255, 255, 255);
   border: 1px solid rgb(217, 217, 217);
   border-radius: 10px;
   padding: 25px;
   box-shadow: 0px 69px 69px rgba(0, 0, 0, 0.2);
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   width: 30vw;
   z-index: 999;

   @media (max-width: 900px) {
      width: 80vw;
   }
}

.newsletter-form {
   display: flex;
   flex-direction: column;
   width: 100%;
}

.newsletterPopup {
   position: fixed; /* Changed from absolute to fixed */
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%) scale(0.1);
   text-align: center;
   visibility: hidden;
   transition: transform 0.4s, top 0.4s;
   z-index: 99;
   box-shadow: 0px 10px 50px 2px rgba(0,0,0, 0.5);
   border-radius: 15px;

   @media (max-width: 800px) {
      width: 90%;
   }
}

.newsletterPopup .xbutton {
   line-height: 12px;
   width: 18px;
   font-size: 20pt;
   cursor: pointer;
   margin-top: 15px;
   margin-right: 11px;
   position:absolute;
   top:0;
   right:0;
   color: black;
   z-index: 99;
}

.close-icon {
   font-size: 30px !important;
   position: absolute;
   top: 10px;
   right: 10px;
   cursor: pointer;
}

.success-container .MuiSvgIcon-root {
   font-size: calc(40px + 0.5vw);
}

.success-container h1 {
   font-size: calc(25px + 0.5vw);
   margin-bottom: 10px;
}

.success-container p {
   margin: 0;
   padding: 5px;
   font-size: calc(16.5px + 0.3vw);
   text-align: center;
}

.success-button {
   background-color: #0091FF;
   width: 100%;
   height: 35px;
   border-radius: 8px;
   border-color: transparent;
   color: white;
   font-size: 17px;
   margin-top: 25px;
   cursor: pointer;
}

.rectangle .error_message {
   color: red;
   font-size: 14px;
   margin: 0;
}

.rectangle .error_container {
   align-self: flex-start;
}

/* Media query for smaller screens */
@media screen and (max-width: 975px) {

   .rectangle {
      margin-top: 0px;
   }

   .content-container {
      padding: 4px 20px; /* Adjusted padding */
      width: calc(100% - 40px); /* Adjusted width to accommodate padding */
   }

   .text-container h2 {
      margin-top: 15px;
      font-size: 24px; /* Adjusted font size */
    }

   .text-container p {
      font-size: 16px; /* Adjusted font size */
      margin-bottom: 5px;
      margin-top: 8px;
      max-width: 100%;
    }

   .input-container {
      width: 100%;
    }

   .input-box {
      width: calc(70% - 20px); /* Adjusted width to accommodate padding */
      font-size: 0.9rem; /* Adjusted font size */
   }

   .subscribe-button {
      font-size: 0.9rem; /* Adjusted font size */
   }
}

.gradient-border {
   --borderWidth: 3px;
   position: relative;
   border-radius: 11px;
 }

 .gradient-border:after {
   content: '';
   position: absolute;
   left: calc(-1 * var(--borderWidth));
   height: calc(100% + var(--borderWidth) * 2);
   width: calc(100% + var(--borderWidth) * 2);
   background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
   z-index: -1;
   animation: animatedgradient 3s ease alternate infinite;
   background-size: 300% 300%;
   border-radius: 13px;
 }

 @keyframes animatedgradient {
   0% {
       background-position: 0% 50%;
   }
   50% {
       background-position: 100% 50%;
   }
   100% {
       background-position: 0% 50%;
   }
 }

 @keyframes borderAnimation {
   0%, 100% {
     transform: scale(100%);
   }
   50% {
     background: #a1a1ff;
     transform: scale(105%);
   }
 }

 .animate-border {
   display: inline-block;
   position: relative;
   animation: borderAnimation 1s linear forwards; /* Adjusted animation duration to 1s */
 }

.animation-active {
  animation: borderAnimation 0.5s infinite linear;
}



