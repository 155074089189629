.all_bookmarks {
   display: flex;
   flex-wrap: wrap;
   justify-content: left; /* Distributes space between boxes */
   margin: 0 auto;
   width: auto;
}

.no_bookmarks {
  margin: 0 auto;
  margin-top: 100px;
  font-size: 21px;
}