.games-nav {
  text-align: center;
  padding: 10px;
  background-color: #0e1213;
}

.games-nav p {
  margin: 0;
  display: inline-block;
  font-size: calc(14px + 0.5vw);
  color: rgb(172, 172, 172);
}

.games-nav a {
  font-size: calc(14px + 0.5vw);
  font-weight: 500;
  padding: 5px 1.2vw;
  margin: 0px 1vw;
  border-radius: 10px;
  color: white;
  transition: color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}

.games-nav a:hover {
  background-color: rgb(255, 69, 69);
  color: black;
}