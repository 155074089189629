.confirm {
   height: 100% !important;
   width: 100% !important;
   margin: 0;
   /* overflow: hidden !important; */
   position: absolute;
 }
 
 .confirm_background {
    width: 100% !important;
    height: 100% !important;
    left: 0;
    top: 0;
    backdrop-filter: blur(3px);
    background-color: rgba(0, 0, 0, 0.1);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    cursor: default;
    text-align: center; /* Center the background content horizontally */
 }
 
 .confirm_background::before {
   content: "";
   background-color: rgba(0, 0, 0, 0.01);
   position: absolute;
   width: 100% !important;
   height: 100% !important;
   z-index: 1;
 }
 
 .confirm_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    margin: 0px 10px;
    opacity: 1;
    border-radius: 10px;
    padding: 10px 20px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
    z-index: 2;
    margin-bottom: 38vh;
    border: 1px solid rgb(217, 217, 217);
    @media (max-width: 900px) {
      margin-bottom: 0px;
    }
 }
 
 .confirm_content h2 {
    font-size: calc(25px + 0.5vw);
    margin-bottom: 10px;
 }
 
 .confirm_content p {
    margin: 0;
    padding: 5px;
    font-size: calc(16.5px + 0.3vw);
 }
 
 .confirm_content button {
    background-color: #0091FF;
    width: 100%;
    height: 35px;
    border-radius: 8px;
    border-color: transparent;
    color: white;
    font-size: 17px;
    margin-top: 25px;
    cursor: pointer;
    margin-bottom: 10px;
 }
 