.searching_background {
  width: 100% !important;
  height: 120% !important;
  transform: translate(0, -100px) ;
  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  cursor: default;
}

.searching_container {
  display: flex;
  position: fixed;
  margin-bottom: 58vh;
}

.searching_container input {
  padding: 20px 200px 20px 60px;
  border-radius: 10px;
  border: 1px solid var(--border-color);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 18px;
  text-align: left;
  outline: none;
}

.searching_container .search_icon {
  margin-top: 20px;
  margin-right: -50px;
  z-index: 9999;
}