.games-settings {
  background-color: #1a2023;
}

.games-settings-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  height: 72vh;
  @media (max-width: 1000px) {
    margin-top: 40px;
  }
}

.games-settings-container h1, .games-settings-container h2, .games-settings-container p {
  color: white;
  margin: 5px;
  text-align: center;
}

.games-settings-container input {
  border: none;
  padding: 5px;
  border-radius: 5px;
  margin: 7px;
  text-align: center;
  font-size: 1.5em;
  width: 85%;
  background-color: rgb(15, 15, 15);
  color: white;
  outline: none;
}

.games-settings-container button {
  padding: 10px 0px;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  background-color: rgb(15, 15, 15);
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  outline: none;
  width: 150px;
}

.games-settings-container form {
  display: flex;
  align-items: center;
}
.games-settings-container button:hover {
  background-color: #555; /* Slightly lighter on hover */
}

.games-settings-container .tab-settings, .games-settings-container .panic-settings {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.tab-presets {
  display: flex;
  flex-direction: row;
}

.tab-presets button {
  margin: 5px;
  width: 60px;
}

.tab-presets button img {
  height: 30px;
}