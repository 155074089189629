.submit-background {
  background: linear-gradient(to right, #56CCF2, #2F80ED);
  height: 74vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
}

.submit-container {
  background: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
}

.submit-heading {
  color: rgb(0, 145, 255);
  margin-bottom: 1rem;
}

.submit-label {
  display: block;
  font-size: 1rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.submit-input,
.submit-textarea {
  width: 95%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
  font-size: calc(0.35vw + 14px);
}

.submit-textarea {
  font-size: calc(0.25vw + 12px);

}

.submit-textarea {
  height: 120px; /* Adjust the height as needed */
  resize: vertical;
}

.submit-btn {
  display: block;
  width: 100%;
  padding: 0.75rem;
  background-color: rgb(0, 145, 255);
  color: white;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s ease-in-out;
}

.submit-btn:hover {
  background-color: rgb(0, 110, 255);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .submit-background, .submit-container {
    padding: 1rem;
  }

  .submit-input, .submit-textarea {
    width: 92%;
  }
}
