.title-container h1 {
   text-align: center; /* Centers the text */
   font-size: 60px; /* Sets a large font size, adjust as needed */
   font-weight: bold; /* Makes the font bold */
   margin: 0; /* Removes default margin */
   padding: 20px 0; /* Adds padding above and below the text */
   color: #000; /* Sets the text color for the whole text */
   margin-top: 5vh;
   margin-bottom: 0px;
}

/* New style for the "AI" span */
.title-container h1 .highlight {
   color: rgb(0, 145, 255); /* Sets the text color for "AI", change the hex code to your preferred color */
}

.title-container .subtitle {
   text-align: center; /* Centers the subtitle */
   font-size: 25px; /* Sets a smaller font size than the main title */
   color: #5a5a5a; /* Sets the subtitle color to be less prominent */
   font-weight: normal; /* Optional: sets the font weight to normal if you want it less bold than the title */
   margin-top: 5px;
   margin-bottom: 50px; /* Adds space between the title and the subtitle */
}

@media screen and (max-width: 800px) {
   .title-container {
      width: 80%; /* Reduced width on smaller screens */
      padding-left: 10%; /* Equal padding on both sides */
      padding-right: 10%;
      margin: 0 auto; /* Center the container */
  }

   .title-container h1 {
       font-size: 40px; /* Smaller font size for mobile */
       padding: 10px 0; /* Less padding for smaller screens */
   }

   .title-container .subtitle {
       font-size: 18px; /* Adjust subtitle font size for readability on mobile */
       width: 90%; /* Reduced width on smaller screens */
       margin: 0 auto; /* Center the container */
       margin-top: 10px;
       margin-bottom: 40px; /* Adds space between the title and the subtitle */
   }
}

.boujee-text-hometitle {
   --bg-size: 400%;
   --color-one: #5ba0ff; /* Lighter Blue */
   --color-two: #0A84FF; /* Original Blue */
   --color-three: #3c92ff; /* Medium Blue */
   --color-four: #5FA4FF; /* Much Lighter Blue */
   background: linear-gradient(
                 90deg,
                 var(--color-one),
                 var(--color-two),
                 var(--color-four),
                 var(--color-two),
                 var(--color-one)
               ) 0 0 / var(--bg-size) 100%;
   color: transparent;
   background-clip: text;
   -webkit-background-clip: text;
   animation: move-bg 8s infinite linear;
 }

 @media (prefers-reduced-motion: no-preference) {
   .boujee-text {
     animation: move-bg 8s linear infinite;
   }
   @keyframes move-bg {
     to {
       background-position: var(--bg-size) 0;
     }
   }
 }

