.games-play {
  background-color: #1a2023;
  color: white;
  min-height: 87vh;
}

.games-play-container {
  display: flex;
  width: 100%;
  height: fit-content;
}

.games-play-container .playing {
  height: 80vh;
  float: left;
  width: 75%;
}

.games-play-container .play-info {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px auto;
  float: right;
  width: 25%;
  border-left: 1px solid rgb(58, 58, 58);
  height: fit-content;
}

.play-info img {
  width: 8vw;
  border-radius: 10px;
  margin-right: 2vw;
}

.play-info h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: calc(15px + 1vw);
}

.play-info p {
  text-align: center;
  padding: 15px;
  font-size: calc(15px + 0.3vw);
}