.ad_container {
   max-width: 100%;
   max-height: 450px;

   @media (max-width: 1030px) {
      max-height: 750px !important;
   }
}

ins.adsbygoogle[data-ad-status="unfilled"] {
   display: none !important;
}