.games-home {
  background-color: #1a2023;
}

.games-home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  height: 83vh;
}

.games-home-container h1 {
  font-size: calc(40px + 1vh);
  margin: 5px 0px;
  text-align: center;
}

.games-home-container p {
  font-size: calc(20px + 0.2vh);
  margin: 0;
}